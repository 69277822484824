@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
  font-family: "Lato-Black";
  src: url("./Fonts/Lato/Lato-Black.ttf");
}

@font-face {
  font-family: "Lato-BlackItalic";
  src: url("./Fonts/Lato/Lato-BlackItalic.ttf");
}

@font-face {
  font-family: "Lato-Bold";
  src: url("../src/Fonts/Lato/Lato-Bold.ttf");
}
@font-face {
  font-family: "Lato-BoldItalic";
  src: url("../src/Fonts/Lato/Lato-BoldItalic.ttf");
}
@font-face {
  font-family: "Lato-Light";
  src: url("../src/Fonts/Lato/Lato-Light.ttf");
}
@font-face {
  font-family: "Lato-LightItalic";
  src: url("../src/Fonts/Lato/Lato-LightItalic.ttf");
}


@font-face {
  font-family: "Lato-Regular";
  src: url("../src/Fonts/Lato/Lato-Regular.ttf");
}


@font-face {
  font-family: "Lato-Thin";
  src: url("../src/Fonts/Lato/Lato-Thin.ttf");
}
@font-face {
  font-family: "Lato-ThinItalic";
  src: url("../src/Fonts/Lato/Lato-ThinItalic.ttf");
}




@font-face {
  font-family: "Roboto-Black";
  src: url("./Fonts/Roboto/Roboto-Black.ttf");
}

@font-face {
  font-family: "Roboto-BlackItalic";
  src: url("./Fonts/Roboto/Roboto-BlackItalic.ttf");
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../src/Fonts/Roboto/Roboto-Bold.ttf");
}
@font-face {
  font-family: "Roboto-BoldItalic";
  src: url("../src/Fonts/Roboto/Roboto-BoldItalic.ttf");
}
@font-face {
  font-family: "Roboto-Light";
  src: url("../src/Fonts/Roboto/Roboto-Light.ttf");
}
@font-face {
  font-family: "Roboto-LightItalic";
  src: url("../src/Fonts/Roboto/Roboto-LightItalic.ttf");
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../src/Fonts/Roboto/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Roboto-MediumItalic";
  src: url("../src/Fonts/Roboto/Roboto-MediumItalic.ttf");
}


@font-face {
  font-family: "Roboto-Regular";
  src: url("../src/Fonts/Roboto/Roboto-Regular.ttf");
}


@font-face {
  font-family: "Roboto-Thin";
  src: url("../src/Fonts/Roboto/Roboto-Thin.ttf");
}
@font-face {
  font-family: "Roboto-ThinItalic";
  src: url("../src/Fonts/Roboto/Roboto-ThinItalic.ttf");
}



@font-face {
  font-family: "Cairo-Black";
  src: url("./Fonts/Cairo/Cairo-Black.ttf");
}



@font-face {
  font-family: "Cairo-Bold";
  src: url("../src/Fonts/Cairo/Cairo-Bold.ttf");
}
@font-face {
  font-family: "Cairo-ExtraBold";
  src: url("../src/Fonts/Cairo/Cairo-ExtraBold.ttf");
}
@font-face {
  font-family: "Cairo-Light";
  src: url("../src/Fonts/Cairo/Cairo-Light.ttf");
}
@font-face {
  font-family: "Cairo-ExtraLight";
  src: url("../src/Fonts/Cairo/Cairo-ExtraLight.ttf");
}

@font-face {
  font-family: "Cairo-Medium";
  src: url("../src/Fonts/Cairo/Cairo-Medium.ttf");
}




@font-face {
  font-family: "Cairo-Regular";
  src: url("../src/Fonts/Cairo/Cairo-Regular.ttf");
}


@font-face {
  font-family: "Cairo-SemiBold";
  src: url("../src/Fonts/Cairo/Cairo-SemiBold.ttf");
}


